import React from "react";
import { Link } from "gatsby";
import { getDirectorUrl } from "../lib/helpers";
import styles from "./page-directors.module.css";

import Section from "./section";
import Container from "./container";

const DirectorsGrid = ({ directors }) => {
  return (
    <Container>
      <div className={styles.grid}>
        {directors.map((director) => (
          <Link
            key={director.slug.current}
            className={styles.director}
            to={getDirectorUrl(director.slug)}
          >
            <img
              src={director.image.asset.fluid.src}
              className={styles.directorShield}
              alt={director.title}
            />
            <div className={styles.directorName}>{director.title}</div>
          </Link>
        ))}
      </div>
    </Container>
  );
};

const PageDirectors = ({ directors }) => {
  return (
    <>
      <Section bg="lightGeo">
        <div className={styles.inner}>
          <h1 className={styles.title}>Our directors</h1>
          <p className={styles.para}>We believe great work starts with trust and team spirit.</p>
        </div>
      </Section>
      <DirectorsGrid directors={directors} />
    </>
  );
};

export default PageDirectors;
