import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import SEO from "../components/seo";

import PageDirectors from "../components/page-directors";

export const query = graphql`
  query DirectorsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    directors: allSanityDirector {
      edges {
        node {
          image {
            asset {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const DirectorsPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const directorNodes = (data || {}).directors
    ? mapEdgesToNodes(data.directors)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <PageDirectors directors={directorNodes} />
    </Layout>
  );
};

export default DirectorsPage;
